<template>
    <div class="mt-5">
        <h3>{{ $t("statistiche_accesso.title") }}</h3>
        <p class="data-articolo my-2">
            <span>Data pubblicazione:</span><br /><strong>{{
                lastUpdated
            }}</strong>
        </p>
        <div class="table-responsive mt-5">
            <div class="table-container position-relative">
                <table class="table" v-if="mesi">
                    <thead
                        :style="{
                            backgroundColor: this.$store.state.Ente.colors
                                ? this.$store.state.Ente.colors.sfondo[0]
                                : '',
                            color: 'white'
                        }"
                    >
                        <tr class="cursor-pointer">
                            <th scope="col">{{ $t("mese") }}</th>
                            <th scope="col">{{ $t("visite") }}</th>
                            <th scope="col" class="d-none d-md-table-cell">
                                {{ $t("durata") }}
                            </th>
                        </tr>
                    </thead>
                    <tbody v-for="(mese, i) in mesi" :key="i">
                        <tr>
                            <td class="text-capitalize">
                                {{
                                    new Date(i)
                                        .toLocaleTimeString("it-IT", {
                                            month: "long"
                                        })
                                        .split(" ")[0]
                                }}
                                {{ new Date(i).getFullYear() }}
                            </td>
                            <td>
                                {{ mese.visite }}
                            </td>
                            <td class="d-none d-md-table-cell">
                                {{ secondsToString(mese.durata, false) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
//@vuese
// @group Views
//Pagina che mostra prenotazioni dell'utente o permette la ricerca con chiave primaria-codice
export default {
    name: "statistiche-accesso",
    data() {
        return {
            mesi: null,
            isLoading: true,
            lastUpdated: null
        };
    },

    mounted() {
        this.getStatistiche();
    },
    methods: {
        /**
         * @vuese
         * funzione asincrona ottiene prenotazioni per l'utente loggato
         */
        async getStatistiche() {
            this.isLoading = true;
            const res = await this.$http.get(`api/analytics`);
            delete res.data.analytics.type;
            this.mesi = res.data.analytics;

            this.lastUpdated = res.data.last_updated;
            this.isLoading = false;
        }
    }
};
</script>

<style>
td {
    text-align: center;
    vertical-align: middle;
}

.cuesor-pointer {
    cursor: pointer;
}

.active {
    color: blue;
}

.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fbfbfb9d;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
